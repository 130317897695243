import styled from "styled-components";

const LearnMoreButtonCard = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LearnMore = () => {
  return (
    <LearnMoreButtonCard className="LearnMoreButtonCard">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 468 186"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            id="LearnMoreButton"
            className="LearnMoreButton"
            d="M58 70.5H410C441.756 70.5 467.5 96.2436 467.5 128C467.5 159.756 441.756 185.5 410 185.5H58C26.2436 185.5 0.5 159.756 0.5 128C0.5 96.2436 26.2436 70.5 58 70.5Z"
            fill="white"
            stroke="#FFAEA6"
          />
          <g id="LearnMoreText">
            <g id="Learn More_2">
              <path
                className="LearnMoreText"
                d="M155.736 135.04H162.456V139H150.606V117.94H155.736V135.04Z"
                fill="#FFAEA6"
              />
              <path
                className="LearnMoreText"
                d="M180.683 130.36C180.683 130.84 180.653 131.34 180.593 131.86H168.983C169.063 132.9 169.393 133.7 169.973 134.26C170.573 134.8 171.303 135.07 172.163 135.07C173.443 135.07 174.333 134.53 174.833 133.45H180.293C180.013 134.55 179.503 135.54 178.763 136.42C178.043 137.3 177.133 137.99 176.033 138.49C174.933 138.99 173.703 139.24 172.343 139.24C170.703 139.24 169.243 138.89 167.963 138.19C166.683 137.49 165.683 136.49 164.963 135.19C164.243 133.89 163.883 132.37 163.883 130.63C163.883 128.89 164.233 127.37 164.933 126.07C165.653 124.77 166.653 123.77 167.933 123.07C169.213 122.37 170.683 122.02 172.343 122.02C173.963 122.02 175.403 122.36 176.663 123.04C177.923 123.72 178.903 124.69 179.603 125.95C180.323 127.21 180.683 128.68 180.683 130.36ZM175.433 129.01C175.433 128.13 175.133 127.43 174.533 126.91C173.933 126.39 173.183 126.13 172.283 126.13C171.423 126.13 170.693 126.38 170.093 126.88C169.513 127.38 169.153 128.09 169.013 129.01H175.433Z"
                fill="#FFAEA6"
              />
              <path
                className="LearnMoreText"
                d="M182.369 130.6C182.369 128.88 182.689 127.37 183.329 126.07C183.989 124.77 184.879 123.77 185.999 123.07C187.119 122.37 188.369 122.02 189.749 122.02C190.929 122.02 191.959 122.26 192.839 122.74C193.739 123.22 194.429 123.85 194.909 124.63V122.26H200.039V139H194.909V136.63C194.409 137.41 193.709 138.04 192.809 138.52C191.929 139 190.899 139.24 189.719 139.24C188.359 139.24 187.119 138.89 185.999 138.19C184.879 137.47 183.989 136.46 183.329 135.16C182.689 133.84 182.369 132.32 182.369 130.6ZM194.909 130.63C194.909 129.35 194.549 128.34 193.829 127.6C193.129 126.86 192.269 126.49 191.249 126.49C190.229 126.49 189.359 126.86 188.639 127.6C187.939 128.32 187.589 129.32 187.589 130.6C187.589 131.88 187.939 132.9 188.639 133.66C189.359 134.4 190.229 134.77 191.249 134.77C192.269 134.77 193.129 134.4 193.829 133.66C194.549 132.92 194.909 131.91 194.909 130.63Z"
                fill="#FFAEA6"
              />
              <path
                className="LearnMoreText"
                d="M208.88 125.05C209.48 124.13 210.23 123.41 211.13 122.89C212.03 122.35 213.03 122.08 214.13 122.08V127.51H212.72C211.44 127.51 210.48 127.79 209.84 128.35C209.2 128.89 208.88 129.85 208.88 131.23V139H203.75V122.26H208.88V125.05Z"
                fill="#FFAEA6"
              />
              <path
                className="LearnMoreText"
                d="M226.783 122.08C228.743 122.08 230.303 122.72 231.463 124C232.643 125.26 233.233 127 233.233 129.22V139H228.133V129.91C228.133 128.79 227.843 127.92 227.263 127.3C226.683 126.68 225.903 126.37 224.923 126.37C223.943 126.37 223.163 126.68 222.583 127.3C222.003 127.92 221.713 128.79 221.713 129.91V139H216.583V122.26H221.713V124.48C222.233 123.74 222.933 123.16 223.813 122.74C224.693 122.3 225.683 122.08 226.783 122.08Z"
                fill="#FFAEA6"
              />
              <path
                className="LearnMoreText"
                d="M267.005 117.94V139H261.875V126.37L257.165 139H253.025L248.285 126.34V139H243.155V117.94H249.215L255.125 132.52L260.975 117.94H267.005Z"
                fill="#FFAEA6"
              />
              <path
                className="LearnMoreText"
                d="M278.314 139.24C276.674 139.24 275.194 138.89 273.874 138.19C272.574 137.49 271.544 136.49 270.784 135.19C270.044 133.89 269.674 132.37 269.674 130.63C269.674 128.91 270.054 127.4 270.814 126.1C271.574 124.78 272.614 123.77 273.934 123.07C275.254 122.37 276.734 122.02 278.374 122.02C280.014 122.02 281.494 122.37 282.814 123.07C284.134 123.77 285.174 124.78 285.934 126.1C286.694 127.4 287.074 128.91 287.074 130.63C287.074 132.35 286.684 133.87 285.904 135.19C285.144 136.49 284.094 137.49 282.754 138.19C281.434 138.89 279.954 139.24 278.314 139.24ZM278.314 134.8C279.294 134.8 280.124 134.44 280.804 133.72C281.504 133 281.854 131.97 281.854 130.63C281.854 129.29 281.514 128.26 280.834 127.54C280.174 126.82 279.354 126.46 278.374 126.46C277.374 126.46 276.544 126.82 275.884 127.54C275.224 128.24 274.894 129.27 274.894 130.63C274.894 131.97 275.214 133 275.854 133.72C276.514 134.44 277.334 134.8 278.314 134.8Z"
                fill="#FFAEA6"
              />

              <path
                className="LearnMoreText"
                d="M294.925 125.05C295.525 124.13 296.275 123.41 297.175 122.89C298.075 122.35 299.075 122.08 300.175 122.08V127.51H298.765C297.485 127.51 296.525 127.79 295.885 128.35C295.245 128.89 294.925 129.85 294.925 131.23V139H289.795V122.26H294.925V125.05Z"
                fill="#FFAEA6"
              />
              <path
                className="LearnMoreText"
                d="M318.407 130.36C318.407 130.84 318.377 131.34 318.317 131.86H306.707C306.787 132.9 307.117 133.7 307.697 134.26C308.297 134.8 309.027 135.07 309.887 135.07C311.167 135.07 312.057 134.53 312.557 133.45H318.017C317.737 134.55 317.227 135.54 316.487 136.42C315.767 137.3 314.857 137.99 313.757 138.49C312.657 138.99 311.427 139.24 310.067 139.24C308.427 139.24 306.967 138.89 305.687 138.19C304.407 137.49 303.407 136.49 302.687 135.19C301.967 133.89 301.607 132.37 301.607 130.63C301.607 128.89 301.957 127.37 302.657 126.07C303.377 124.77 304.377 123.77 305.657 123.07C306.937 122.37 308.407 122.02 310.067 122.02C311.687 122.02 313.127 122.36 314.387 123.04C315.647 123.72 316.627 124.69 317.327 125.95C318.047 127.21 318.407 128.68 318.407 130.36ZM313.157 129.01C313.157 128.13 312.857 127.43 312.257 126.91C311.657 126.39 310.907 126.13 310.007 126.13C309.147 126.13 308.417 126.38 307.817 126.88C307.237 127.38 306.877 128.09 306.737 129.01H313.157Z"
                fill="#FFAEA6"
              />
            </g>
          </g>
          <g id="LearnMoreLogo">
            <path
              id="LearnMoreLogoInternal"
              d="M61.5 104.5C32.7812 104.5 9.49999 81.2188 9.49999 52.5C9.49999 23.7812 32.7812 0.5 61.5 0.5C90.2188 0.5 113.5 23.7812 113.5 52.5C113.5 81.2188 90.2188 104.5 61.5 104.5Z"
              fill="white"
              stroke="#FFAEA6"
            />
          </g>
          <g id="Group 6">
            <g id="Group 4">
              <path
                id="Ellipse 13"
                d="M107 53C107 77.8528 86.8528 98 62 98C37.1472 98 17 77.8528 17 53C17 28.1472 37.1472 8 62 8C86.8528 8 107 28.1472 107 53Z"
                fill="#FEF3F3"
              />
              <path
                id="Ellipse 7"
                d="M96.7728 52.5985C96.7728 57.4563 92.8347 61.3943 87.977 61.3943C83.1192 61.3943 79.1812 57.4563 79.1812 52.5985C79.1812 47.7408 83.1192 43.8027 87.977 43.8027C92.8347 43.8027 96.7728 47.7408 96.7728 52.5985Z"
                fill="#FFAEA6"
              />
              <path
                id="Ellipse 10"
                d="M94.134 52.598C94.134 55.9984 91.3773 58.755 87.9769 58.755C84.5764 58.755 81.8198 55.9984 81.8198 52.598C81.8198 49.1975 84.5764 46.4409 87.9769 46.4409C91.3773 46.4409 94.134 49.1975 94.134 52.598Z"
                fill="#FEF3F3"
              />
              <path
                id="Ellipse 11"
                d="M59.8304 33.2475C59.8304 38.1053 55.8924 42.0433 51.0346 42.0433C46.1768 42.0433 42.2388 38.1053 42.2388 33.2475C42.2388 28.3897 46.1768 24.4517 51.0346 24.4517C55.8924 24.4517 59.8304 28.3897 59.8304 33.2475Z"
                fill="#FFAEA6"
              />
              <path
                id="Ellipse 12"
                d="M57.1916 33.2479C57.1916 36.6483 54.435 39.4049 51.0345 39.4049C47.6341 39.4049 44.8774 36.6483 44.8774 33.2479C44.8774 29.8474 47.6341 27.0908 51.0345 27.0908C54.435 27.0908 57.1916 29.8474 57.1916 33.2479Z"
                fill="#FEF3F3"
              />
              <g id="Group 7">
                <path
                  id="Ellipse 13_2"
                  d="M43.5438 68.4779C43.5438 73.3357 39.6057 77.2737 34.748 77.2737C29.8902 77.2737 25.9521 73.3357 25.9521 68.4779C25.9521 63.6201 29.8902 59.6821 34.748 59.6821C39.6057 59.6821 43.5438 63.6201 43.5438 68.4779Z"
                  fill="#FFAEA6"
                />
                <path
                  id="Ellipse 14"
                  d="M40.9049 68.4779C40.9049 71.8783 38.1483 74.6349 34.7479 74.6349C31.3474 74.6349 28.5908 71.8783 28.5908 68.4779C28.5908 65.0774 31.3474 62.3208 34.7479 62.3208C38.1483 62.3208 40.9049 65.0774 40.9049 68.4779Z"
                  fill="#FEF3F3"
                />
              </g>
            </g>
            <path
              id="Ellipse 7_2"
              d="M85.338 37.2054C85.338 39.8772 83.1721 42.0431 80.5003 42.0431C77.8285 42.0431 75.6626 39.8772 75.6626 37.2054C75.6626 34.5336 77.8285 32.3677 80.5003 32.3677C83.1721 32.3677 85.338 34.5336 85.338 37.2054Z"
              fill="#FFAEA6"
            />
            <path
              id="Ellipse 10_2"
              d="M83.5789 37.2055C83.5789 38.9057 82.2006 40.284 80.5004 40.284C78.8002 40.284 77.4219 38.9057 77.4219 37.2055C77.4219 35.5053 78.8002 34.127 80.5004 34.127C82.2006 34.127 83.5789 35.5053 83.5789 37.2055Z"
              fill="#FEF3F3"
            />
            <path
              id="Ellipse 11_2"
              d="M43.9975 47.3208C43.9975 49.7496 42.0285 51.7187 39.5996 51.7187C37.1707 51.7187 35.2017 49.7496 35.2017 47.3208C35.2017 44.8919 37.1707 42.9229 39.5996 42.9229C42.0285 42.9229 43.9975 44.8919 43.9975 47.3208Z"
              fill="#FFAEA6"
            />
            <path
              id="Ellipse 12_2"
              d="M42.2384 47.3204C42.2384 48.7777 41.057 49.9591 39.5997 49.9591C38.1423 49.9591 36.9609 48.7777 36.9609 47.3204C36.9609 45.863 38.1423 44.6816 39.5997 44.6816C41.057 44.6816 42.2384 45.863 42.2384 47.3204Z"
              fill="#FEF3F3"
            />
            <path
              id="Ellipse 13_3"
              d="M73.024 74.1478C73.024 76.8195 70.8581 78.9854 68.1863 78.9854C65.5145 78.9854 63.3486 76.8195 63.3486 74.1478C63.3486 71.476 65.5145 69.3101 68.1863 69.3101C70.8581 69.3101 73.024 71.476 73.024 74.1478Z"
              fill="#FFAEA6"
            />
            <path
              id="Ellipse 14_2"
              d="M71.265 74.1479C71.265 75.8481 69.8867 77.2264 68.1864 77.2264C66.4862 77.2264 65.1079 75.8481 65.1079 74.1479C65.1079 72.4476 66.4862 71.0693 68.1864 71.0693C69.8867 71.0693 71.265 72.4476 71.265 74.1479Z"
              fill="#FEF3F3"
            />
            <path
              id="Ellipse 15"
              d="M68.6268 53.0378C68.6268 56.6811 65.6733 59.6346 62.03 59.6346C58.3866 59.6346 55.4331 56.6811 55.4331 53.0378C55.4331 49.3944 58.3866 46.4409 62.03 46.4409C65.6733 46.4409 68.6268 49.3944 68.6268 53.0378Z"
              fill="#FFAEA6"
            />
            <rect
              id="Rectangle 6"
              x="78.374"
              y="40.3521"
              width="14.6242"
              height="1.60103"
              transform="rotate(139.63 78.374 40.3521)"
              fill="#FFAEA6"
            />
            <rect
              id="Rectangle 7"
              x="67.7471"
              y="51.7183"
              width="13.9642"
              height="2.54978"
              fill="#FFAEA6"
            />
            <rect
              id="Rectangle 8"
              x="55.1621"
              y="38.5337"
              width="10.7564"
              height="2.54978"
              transform="rotate(59 55.1621 38.5337)"
              fill="#FFAEA6"
            />
            <path
              id="Rectangle 9"
              d="M42.852 47.6577L56.4014 51.0359L56.0141 52.5892L42.4648 49.211L42.852 47.6577Z"
              fill="#FFAEA6"
            />
            <rect
              id="Rectangle 10"
              x="41.3037"
              y="63.5"
              width="17.0447"
              height="2.54978"
              transform="rotate(-29 41.3037 63.5)"
              fill="#FFAEA6"
            />
            <rect
              id="Rectangle 11"
              x="66.2749"
              y="70.0898"
              width="11.3055"
              height="1.60084"
              transform="rotate(-105 66.2749 70.0898)"
              fill="#FFAEA6"
            />
            <path
              id="Ellipse 16"
              d="M65.9875 52.9894C65.9875 55.1754 64.2154 56.9475 62.0294 56.9475C59.8434 56.9475 58.0713 55.1754 58.0713 52.9894C58.0713 50.8034 59.8434 49.0312 62.0294 49.0312C64.2154 49.0312 65.9875 50.8034 65.9875 52.9894Z"
              fill="#FEF3F3"
            />
          </g>
        </g>
      </svg>
    </LearnMoreButtonCard>
  );
};

export default LearnMore;
